import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from "antd";


//TODO andra fatto un unico coggetto che contenga regole e placeholder insieme da cui fare uno switch e dinamicamente impostare le varie cose

const RegCodeFormItem = (props) => {
    // console.log("RegCodeFormItem PROPS ", props);
    const {intl, form, formItemLayout, entityType, initialValue,nationalityCode} = props;
    // const regCodeRules = () => {
    //     if (entityType === 1) {
    //         return [
    //             {
    //                 required: true,
    //                 message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.message'}),
    //             },
    //             {
    //                 len: 16,
    //                 message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.len'}),
    //             }
    //             ,
    //             {
    //                 pattern: /[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z]$/,
    //                 message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.pattern'}),
    //             }
    //         ]
    //     } else {
    //         return [
    //             {
    //                 required: true,
    //                 message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.message'}),
    //             }
    //         ]
    //     }
    // }


    function entityTypeSelector(entityType) {
        switch (entityType) {
            case  1 :
                // return intl.formatMessage({id: 'codicefiscale'});
                if (nationalityCode === 'IT')
                return {
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.message'}),
                        },
                        {
                            len: 16,
                            message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.len'}),
                        }
                        ,
                        {
                            pattern: /[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z]$/,
                            message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.pattern'}),
                        }
                    ],
                    placeholder: intl.formatMessage({id: 'contact.detail.form.registrant.fiscalCode'})
                }
                else
                    return {
                        rules: [
                            {
                                required: true,
                                message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.message'}),
                            },
                            // {
                            //     len: 16,
                            //     message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.len'}),
                            // }
                            // ,
                            // {
                            //     pattern: /[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z]$/,
                            //     message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.pattern'}),
                            // }
                        ],
                        placeholder: intl.formatMessage({id: 'contact.detail.form.registrant.fiscalCode'})
                    }
            case  7 :
                return intl.formatMessage({id: 'contact.detail.form.registrant.vat'});
            case  4 :
                return {
                    rules: [
                        {
                            required: false,
                        }
                    ],
                    placeholder: intl.formatMessage({id: 'contact.detail.form.registrant.na'})
                }

            default:
                return {
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({id: 'contact.detail.form.registrant.regCode.message'}),
                        }
                    ],
                    placeholder: intl.formatMessage({id: 'contact.detail.form.registrant.piva'})
                }
        }

    }

    return (
        <Form.Item className="ant-row ant-from-item"
                   label={intl.formatMessage({id: 'contact.detail.form.registrant.regCode'})}
                   {...formItemLayout}
                   hasFeedback
        >
            {form.getFieldDecorator('registrant.regCode', {
                initialValue: entityType === 4 ? 'n.a.':initialValue ,
                rules: entityTypeSelector(entityType).rules,
            })(
                <Input
                    placeholder={entityTypeSelector(entityType).placeholder}
                    style={entityType === 1 ? {textTransform: 'uppercase'} : {}}
                    //il campo una volta impostato, non è più modificabile
                    disabled={!!initialValue || entityType === 4}
                    // onChange={this.setToUpperCase}
                />)}
        </Form.Item>);
};

RegCodeFormItem.propTypes = {
    disabled: PropTypes.bool,
    initialValue: PropTypes.string,
    entityType: PropTypes.number,
};
RegCodeFormItem.defaultProps = {};

export default RegCodeFormItem;
